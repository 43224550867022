<template>
  <div>
    <div class="text-h4 ml-1">My labels</div>
    <v-btn rounded color="primary" class="my-4" @click="isLabelCreateDialogOpen = true">
      <v-icon left>mdi-plus</v-icon>
      Create Label
    </v-btn>
    <v-card rounded outlined class="my-4">
      <v-card-text v-if="!userCollections.length">You haven't created any labels yet.</v-card-text>
      <v-list v-else color="transparent">
        <v-list-item
            v-for="label in userCollections"
            :key="label.id"
        >
          <v-list-item-icon>
            <v-icon>mdi-tag-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ label.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ label.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="deleteCollection(label.id)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <v-dialog v-model="isLabelCreateDialogOpen" width="400">
      <label-create @close="isLabelCreateDialogOpen = false" />
    </v-dialog>

  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import LabelCreate from "@/components/Label/LabelCreate.vue";

export default {
  name: "Template",
  components: {
    LabelCreate,
  },
  props: {},
  data() {
    return {
      foo: 42,
      isLabelCreateDialogOpen: false,
    }
  },
  computed: {
    ...mapGetters([

      "entityType",
    ]),
    ...mapGetters("user", [
      "userId",
      "userCollections",
    ]),
  },

  methods: {
    ...mapMutations([
      "snackbar",
    ]),
    ...mapActions([]),
    ...mapActions("user", [
      "deleteCollection",
    ]),


  },
  created() {
  },
  mounted() {
  },
  watch: {}
}
</script>

<style scoped lang="scss">

</style>